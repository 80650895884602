import React, { Component } from 'react'
import {connect} from 'react-redux'
import {createProject} from "../../store/actions/taskActions.js"
import { Redirect } from "react-router-dom"
import {
    Form,
    Input,
    Button,
    FormGroup,Col,
    Row
} from "reactstrap" 

class CreateProject  extends Component {
    state = {
        title: ""
    }
    
    handleChange = (e) => {
        this.setState({
          [e.target.id] : e.target.value  
        })
    }

    handleSubmit = (e) => {
        console.log("CreateProject: handleSubmit")
        e.preventDefault();
        this.props.onProjectCreated(this.state.title)
        this.props.createProject(this.state)
        this.setState({
            title: ""
        })
        
    }

    render() {
        const {auth} = this.props
        if (!auth.uid) return <Redirect to="/signin"></Redirect>
        return (
            <Form onSubmit={this.handleSubmit} className="mt-3">
            
                <Row>
                    <Col >
                
                    <FormGroup> 
                        <Input type="text" value={this.state.title}  placeholder="New Project" id="title" onChange={this.handleChange}/>
                    </FormGroup>
               </Col> 
               <Col sm="4" >
                    <Button block >Add Project</Button>
                </Col></Row>
            </Form>    
           
        )
    }
}

const mapStateToProps = state => ({ 
    auth: state.firebase.auth 
})


const mapDispatchToProps = dispatch => ({
    createProject: (project) => dispatch(createProject(project))
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateProject)