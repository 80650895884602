import React, { Component } from 'react'
import { connect } from "react-redux"
import { compose } from "redux"
import { Button,Card,Collapse,CardBody,Input,Alert,CardHeader } from "reactstrap"
import moment from "moment"
import Sound from "react-sound"
import ProjectSelect from "../tasks/ProjectSelect"
import {
    tickSession,
    startSession,
    pauseSession,
    selectSessionTask, 
    saveSession,
    startPauseSession
} from "../../store/actions/sessionActions" 
import { completeTask,updateTask } from "../../store/actions/taskActions"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import firebase from "../../config/fbConfig"


class Session extends Component {
    state = {
        isPlaying: "STOPPED",
        collapse: true

    }
    
    toggle = () => { 
        this.setState({ collapse: !this.state.collapse });
    }

    playSound = () => {
        //console.log("playing sound")
        this.setState({isPlaying:"PLAYING"})
    }
    dontPlaySound = () => {
        //console.log("stop playing sound")
        this.setState({isPlaying:"STOPPED"})
    }


    handleTick = async () => {
        console.log("handleTick", this.props.session )
        const now = moment()
        const setStart = moment(this.props.session.setStartedAt)
        const setDuration = moment.duration(now.diff(setStart))
        //console.log("set duration: ", setDuration.as("seconds"))
        //console.log("total duration: ", moment.duration(this.props.session.totalRunningTime).as("seconds"))
        
        const time_left = moment.duration(this.props.session.length - this.props.session.totalRunningTime)
        //console.log("time left: ", time_left.as("seconds"))

        if (time_left.as("seconds") < 2){

            if (this.props.session.isPauseSession){
                
                const prevTaskId = this.props.session.prevTaskId
                const prevProjectId = this.props.session.prevProjectId
                console.log("Pause session ended. Prevtask: ",prevTaskId)
                clearInterval(this.intervalHandle)
                this.props.resetSession()
                this.props.selectProjectId(prevProjectId)
                let prevTask = await this.getTask(prevTaskId)
                console.log("Got prevtask,", prevTask)
                this.props.selectSessionTask(prevTask)
                this.playSound()

            }else {
                this.props.saveSession(this.props.session)
                this.props.resetSession(this.props.session)
                console.log("session after reset: ", this.props.session)
                this.playSound()
                this.props.startPauseSession(this.props.session,this.props.settings.pauseLength);
            }

        } else {
            const tickUpdate = {
                ...this.props.session,
                setRunningTime: setDuration.as("milliseconds"),
                totalRunningTime: setDuration.add(this.props.session.prevTotalRunningTime).as("milliseconds")
            }
            //console.log("tick update: ", tickUpdate)
            this.props.tickSession(tickUpdate)
        }
        this.props.sendStateToDevice() 
    }

    getTask = (taskId) => {
        return new Promise((resolve) => {
        const db = firebase.firestore();
        db.collection('tasks').doc(taskId).get().then((doc) => {
            console.log(doc.data())

            resolve({
                ...doc.data(),
                id: taskId
            })    
        }).catch(function(error) {
            console.log("Error getting document:", error);
        })    
        })
    }


    componentDidUpdate = (prevProps, prevState) => {
        //Check if device button was pressed
        if (!prevProps.device.buttonPushed && this.props.device.buttonPushed){
            this.toogleSession()
            this.props.buttonHandled();
        }
    }


    handleTaskSelected = (e) => {
        const taskId = e.target.value
        let task = {id:0}
        if (taskId != 0){
            task = this.props.tasks.filter((task) => {return task.id == e.target.value})[0];
        }        
        this.props.selectSessionTask(task)
        this.props.sendStateToDevice()  

    }

    handleProjectSelected = (projectId) => {
        console.log(projectId)
        this.props.selectProjectId(projectId)
    }


    handleCancelSession = () => {
        const {session} = this.props
        // stop running session or pause
        if (session.running){
            clearInterval(this.intervalHandle)
        }
        this.props.resetSession()
        this.playSound()
        this.props.sendStateToDevice()  
    }

    handleCompleteTask = () => {
       
        if (!this.props.session.isPauseSession){

            this.playSound()
            this.props.completeTask(this.props.session.taskId)
            
            if (this.props.session.running){
                this.props.saveSession(this.props.session)    
                this.props.resetSession()
                this.props.startPauseSession(this.props.settings.pauseLength);
                
            }else{
                this.props.resetSession()
            }
            
        }
        this.props.sendStateToDevice()  

    }



    toogleSession = () => {
       
        // If session running - stop it
        if (this.props.session.running){
           
            const now = moment();
            const start = moment(this.props.session.intervallStarted)
            const duration = moment.duration(now.diff(start)).add(this.props.session.runningTime) 
    
            this.props.pauseSession({
                ...this.props.session,
                prevTotalRunningTime: this.props.session.totalRunningTime,
                running: 0
            })

            this.props.sendStateToDevice()    
            //stop ticker
            clearInterval(this.intervalHandle);

        } else { // Else start session
            
            this.props.startSession({
                ...this.props.session,
                length: this.props.settings.sessionLength,
                setStartedAt: Date.now(),
                running: 1
            })
            this.props.sendStateToDevice(1)  
            // start ticker
            this.intervalHandle = setInterval(this.handleTick, 1000);
        }

    }

    render() {
        console.log("Session render()")
        //console.log("settings: ", this.props.settings)
        console.log("session: ", this.props.session)
        
        const { tasks,projects,session, settings } = this.props
        //const sessionTask  = session.task
        //const sessionTaskId = sessionTask ? sessionTask.id : 0
        let time_left = moment(settings.sessionLength).format("mm:ss")

        if (session.setStartedAt){
            time_left = moment(session.length).subtract(session.totalRunningTime).format("mm:ss") 
        }
        //console.log("sessionTask", sessionTask)
        //console.log("sessionTaskId",sessionTaskId)

        const readyToStart = session.taskId != 0 && !session.isPauseSession 

        let playButtonContent = null
        if (session.running){
            playButtonContent = <span><FontAwesomeIcon icon="pause" className="mr-2"/>Pause</span>
        }
        else if (session.task && session.interuptions > 0){
            playButtonContent = <span><FontAwesomeIcon icon="play" className="mr-2"/>Continue</span>
        }
        else if (session.isPauseSession ){
            playButtonContent = <span><FontAwesomeIcon icon="play" className="mr-2"/>Continue</span>
        }
        else {
            playButtonContent = <span><FontAwesomeIcon icon="play" className="mr-2"/>Start</span>
        }
        return (

            
            
            <Card className="mb-3" style={{"background":"rgba(255,255,255,0.7)"}}> 
                <CardHeader onClick={this.toggle}>DO WORK</CardHeader>

                <Collapse isOpen={this.state.collapse}>
                <CardBody className="text-center">
                    {
                        session.isPauseSession ? 
                            <Alert color="danger">Enjoy a short break!</Alert>    
                        : null
                    }
                    {
                        session.running && !session.isPauseSession ? 
                            <Alert color="success">Working with <strong>{session.task.title}</strong>
                            </Alert>    
                        : null
                    }

                    {
                        session.isPauseSession || session.running ? null :    
                    <div>
                    <ProjectSelect projects={projects} selectedProjectId={session.projectId} onProjectSelected= {this.handleProjectSelected}/>

                    <Input disabled={this.props.session.running || this.props.session.projectId == 0 } value={session.taskId} type="select" className="mt-2" bsSize="lg" onChange={this.handleTaskSelected}>
                        <option value="">Select task</option>
                        { tasks && tasks.map(task => { 
                            if (task.completed != 1){
                            return (
                                <option value={task.id} key={task.id}>{task.title}</option>
                            )}
                        })}
                    </Input>


                    </div>
                    }
                    <h1 className="mb-4 mt-4">{time_left}</h1>
                    
                        <Button disabled={!readyToStart} size="lg" className="mr-2" onClick={this.toogleSession} color={this.props.session.running?"secondary":"dark"}>
                            {playButtonContent}
                        </Button>

                        <Button disabled={session.taskId == 0 } onClick={this.handleCompleteTask} size="lg" outline color="secondary" className="mr-2"><FontAwesomeIcon icon="check-square" size="lg"/></Button>
                        <Button disabled={session.startedAt == 0 } onClick={this.handleCancelSession} size="lg"  outline color="secondary"><FontAwesomeIcon icon="ban" size="lg"/></Button>
                   
                    <Sound url="/mp3/bell.mp3" playStatus={this.state.isPlaying} onFinishedPlaying={this.dontPlaySound}></Sound>
                </CardBody>
                </Collapse>
            </Card>
          
        )
    }
}



const mapStateToProps = (state, ownProps) => {
    return {
        settings: state.settings,
        device: state.device,
        auth: state.firebase.auth
    }
}

const mapDispathToProps = (dispatch) => {
    return {
        startSession: (session) => dispatch(startSession(session)),
        pauseSession: (session) => dispatch(pauseSession(session)),
        tickSession: (session) => dispatch(tickSession(session)),
        buttonHandled: () => dispatch({type:"BUTTON_HANDLED"}),
        sendStateToDevice: () => dispatch({type:"SEND_STATE_TO_DEVICE"}),
        resetSession: (session) => dispatch({type:"RESET_SESSION",session}),
        selectSessionTask: (task) => dispatch(selectSessionTask(task)),
        selectSessionTaskId: (taskId) => dispatch(selectSessionTask({id:taskId})),
        saveSession: (session) => dispatch(saveSession(session)),
        startPauseSession: (session,length) => dispatch(startPauseSession(session,length)),
        completeTask: (task) => dispatch(completeTask(task)),
        updateTask: (task) => dispatch(updateTask(task)),
        selectProjectId: (projectId) => dispatch({type:"SELECT_PROJECT",projectId})
    }
}

  
export default compose (
    connect(mapStateToProps,mapDispathToProps)
)(Session)
