import React from "react"
import { connect } from "react-redux"
//import { firestoreConnect } from "react-redux-firebase"
import { compose } from "redux"
import { Redirect } from "react-router-dom"

import ListTasks from "../tasks/ListTasks"
import {Container,Button} from "reactstrap"
import Session from "../session/Session"
import Settings from ".././dashboard/Settings"
import { firestoreConnect } from "react-redux-firebase"

class Dashboard extends React.Component{

    getUnique = (arr, comp) => {

        const unique = arr
             .map(e => e[comp])
      
           // store the keys of the unique objects
          .map((e, i, final) => final.indexOf(e) === i && i)
      
          // eliminate the dead keys & store unique objects
          .filter(e => arr[e]).map(e => arr[e]);
      
         return unique;
    }
      
    handleSelectProject = (e) => {
        const projectId = e.target.value
        this.props.selectProjectId(projectId)
    }

    render () {
        console.log("Render Dashboard" , this.props.tasks)
        let uniqueTasks = null
        if (this.props.tasks){
            uniqueTasks = [...this.getUnique(this.props.tasks,"id")]
        }
        const {auth} = this.props
        if (!auth.uid) return <Redirect to="/login"></Redirect>

        return (   
            <div>
                <ListTasks stasks={uniqueTasks} session={this.props.session} projects={this.props.projects} onProjectSelected={this.handleSelectProject}/>
                <Session tasks={uniqueTasks} session={this.props.session} projects={this.props.projects}/>
                <Settings/>
            </div>
        )
    }
}
const mapStateToProps = (state) => {

    return( {
        auth: state.firebase.auth,
        tasks: state.firestore.ordered.tasks,
        session: state.session,
        projects: state.firestore.ordered.projects
    })
}

const mapDispatchToProps = (dispatch) => {
    return ({
        selectProjectId: (projectId) => dispatch({type:"SELECT_PROJECT",projectId})
    })

}

const connectQuery = (props) => {
    const uid = props.auth.isEmpty ? null : props.auth.uid
    const projectId = props.session.projectId ? props.session.projectId : 0
    return [
        { 
            collection: "tasks" , 
            where: [
                ['ownerId', '==', uid],
                ['projectId','==',projectId]
            ]
        },
        { 
            collection: "projects" , 
            where: [
                ['ownerId', '==', uid]
                //,
                //['completed','==',0]
            ]
        }
    ]
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect(connectQuery)    
)(Dashboard)