import React from "react"
import { connect } from "react-redux"
import { firestoreConnect } from "react-redux-firebase"
import { compose } from "redux"
import { 
    Card,CardBody,CardTitle,
    Col,Collapse,CardHeader,Button,
    Row,ListGroup,ListGroupItem,UncontrolledCollapse,Input,Form,FormGroup,Label,CustomInput,ListGroupItemHeading,ListGroupItemText
} from "reactstrap"
import { NavLink,Redirect } from "react-router-dom"
import CreateTask from "./CreateTask"
import CreateProject from "./CreateProject"
import ProjectSelect from "./ProjectSelect"
import { deleteTask,updateTask,completeTask } from "../../store/actions/taskActions"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    tickSession,
    startSession,
    pauseSession,
    selectSessionTask, 
    saveSession,
    startPauseSession
} from "../../store/actions/sessionActions" 
import TaskTitle from "./TaskTitle"
import firebase from "../../config/fbConfig"

class ListTasks extends React.Component {

    state = {
        collapse: false,
        listProjectId: 0,
        tasks: null,
        recentlyCreateProject:""
    }


    toggle = () => { 
        this.setState({ collapse: !this.state.collapse });
    }

    /*
    handleDoWork = (id) => {
        const task = this.props.tasks.filter((task) => {return task.id == id})[0];
        this.props.selectSessionTask(task)
    }
    */

    handleProjectSelected = (e) => {
        this.setSelectedProject(e.target.value)
    }
    
    setSelectedProject = (projectId) => {
        console.log("setSelectedProject", projectId)
        this.setState({listProjectId: projectId})
        this.loadTasks(projectId);
    }

    setSelectedProjectByTitle = (title) => {
        this.setState({recentlyCreateProject: title})
    }    


    handleCompleteTask = (task) => {
       
        if (!task.completed){
            this.props.completeTask(task)
            if (this.props.session.running && this.props.session.task.id == task.id){
                this.props.saveSession(this.props.session)    
                const sessionCount = this.props.session.task.sessionCount ? this.props.session.task.sessionCount + 1 : 1
                this.props.updateTask({
                    ...this.props.session.task,
                    sessionCount
                })
                this.props.resetSession()
                this.props.startPauseSession(this.props.settings.pauseLength);    
            }else{
                this.props.resetSession()
            }
            this.props.sendStateToDevice() 
        } else {
            //uncomplete task
            this.props.updateTask({
                ...task,
                completed: 0
            })

        }
    }

    loadTasks = (projectId) => {
        console.log("get tasks for ", projectId)
        if (projectId){
            const db = firebase.firestore();
            db.collection('tasks').where('projectId','==',projectId).get().then((querySnapshot) => {
                var data = querySnapshot.docs.map(function (documentSnapshot) {
                        //console.log("returning data:", documentSnapshot.data() )
                        return {
                            ...documentSnapshot.data(),
                            id:documentSnapshot.id
                        };
                    });
                    console.log("TASKS:", data)
                    this.setState({ tasks : data }) 
            })
        }
    }

    componentDidUpdate = () => {
        console.log("componentDidMount",this.state.recentlyCreateProject)
        if (this.state.recentlyCreateProject != ""){
            this.props.projects.forEach(project => {
                if (project.title === this.state.recentlyCreateProject){
                    this.setSelectedProject(project.id)
                    this.setState({recentlyCreateProject: ""})
                }
            })
            
        }
    }


    render() {

        if (!this.props.auth.uid) return <Redirect to="/"></Redirect>
        const { session, projects} = this.props
        const { tasks } = this.state
        console.log('Render ListTasks',tasks)
        return (
           
           <Card className="mb-3" style={{"background":"rgba(255,255,255,0.7)"}}>
                <CardHeader onClick={this.toggle}>MANAGE TASKS</CardHeader>
                    <Collapse isOpen={this.state.collapse}>
                        <CardBody >

                        <ProjectSelect editProject={true} projects={projects} selectedProjectId={this.state.listProjectId} onProjectSelected={this.setSelectedProject}/>
                       

                        {this.state.listProjectId == 0 ? <CreateProject onProjectCreated={this.setSelectedProjectByTitle}/> : null}    
                
                    <ListGroup className="mt-2">
                    { tasks && tasks.map(task => {
                    return (
                        <TaskTitle key={task.id} task={task} session={session} onDelete={this.props.deleteTask} onComplete={this.handleCompleteTask} />
                    )
                    })}
                    </ListGroup>
                 
                  {this.state.listProjectId != 0 ? <CreateTask onTaskCreated={this.loadTasks} projectId={this.state.listProjectId}/> : null }
                
                </CardBody>
                
                </Collapse>
            </Card>
        )
    }


}




const mapStateToProps = (state) => {
    return ({
        tasks: state.firestore.ordered.tasks,
        auth: state.firebase.auth,
        session: state.session,
        settings: state.settings
    })
}
const mapDispatchToProps = (dispatch) => {
    return( {
        selectSessionTask: (task) => dispatch(selectSessionTask(task)),
        deleteTask: (id) => dispatch(deleteTask(id)),
        updateTask: (task) => dispatch(updateTask(task)),
        completeTask: (task) => dispatch(completeTask(task)),
        resetSession: () => dispatch({type:"RESET_SESSION"}),
        saveSession: (session) => dispatch(saveSession(session)),
        startPauseSession: (length) => dispatch(startPauseSession(length)),
        sendStateToDevice: () => dispatch({type:"SEND_STATE_TO_DEVICE"}),
    })
}

const connectQuery = (props,state) => {
    console.log(props,state)
    const uid = props.auth.isEmpty ? null : props.auth.uid
    const projectId = state.listProject ? state.listProject : 0
    return [
        { 
            collection: "tasks" , 
            where: [
                ['ownerId', '==', uid],
                ['projectId','==',projectId]
            ]
        }
    ]
}


export default compose(
    connect(mapStateToProps,mapDispatchToProps),
    firestoreConnect(connectQuery)
)(ListTasks)
