import firebase from 'firebase/app'
import 'firebase/firestore'
import "firebase/auth" 


// Initialize Firebase
var config = {
    apiKey: "AIzaSyD-VGdR1g6hKp80gy8ke6Lyuq_sgjFLVBI",
    authDomain: "pomodoro-1337.firebaseapp.com",
    databaseURL: "https://pomodoro-1337.firebaseio.com",
    projectId: "pomodoro-1337",
    storageBucket: "pomodoro-1337.appspot.com",
    messagingSenderId: "1020792995578"
};
firebase.initializeApp(config);  
  
export default firebase  