const initState = {
    

}
const taskReducer = (state = initState, action) => {
    switch(action.type){
        case "CREATE_TASK": 
            console.log("create task", action.task)
            return state
        case "CREATE_TASK_ERROR":
            console.log("Create task error: ", action.err)  
            return state 
        case "DELETE_TASK":
            console.log("delete task")
            return state
        case "COMPLETE_TASK":
            return state
        case "UPDATE_TASK":
            return state
        case "CREATE_PROJECT":
            return state
            
        default:
            return state
    }
}
export default taskReducer