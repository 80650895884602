export const createTask = (task,projectId) => {
  
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        console.log("createTask: ",task)
        const firestore = getFirestore()
        //const profile = getState().firebase.profile
        const ownerId = getState().firebase.auth.uid
        firestore.collection("tasks").add({
            ...task,
            ownerId,
            projectId,
            completed: 0,
            createdAt: new Date()
        })
        .then(() => {
            dispatch({type: "CREATE_TASK", task})
        }).catch((err) => {
            dispatch({type: "CREATE_TASK_ERROR", err})
        })
    }
}  
export const createProject = (task) => {
  
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        const firestore = getFirestore()
        //const profile = getState().firebase.profile
        const ownerId = getState().firebase.auth.uid
        firestore.collection("projects").add({
            ...task,
            ownerId,
            createdAt: new Date()
        })
        .then(() => {
            dispatch({type: "CREATE_PROJECT", task})
        }).catch((err) => {
            dispatch({type: "CREATE_PROJECT", err})
        })
    }
}  

export const updateTask = (task) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        const firestore = getFirestore()
        //const profile = getState().firebase.profile
        const ownerId = getState().firebase.auth.uid
        firestore.collection("tasks").doc(task.id).update({
            ...task,
            updatedAt: new Date()
        })
        .then(() => {
            dispatch({type: "UPDATE_TASK", task})
        }).catch((err) => {
            dispatch({type: "UPDATE_TASK_ERROR", err})
        })
    }
}


export const updateProject = project => 
    (dispatch, {getFirestore}) => {
        const firestore = getFirestore()
        firestore.collection("projects").doc(project.id).update({
            ...project,
            updatedAt: new Date()
        })
        .then(() => {
            dispatch({type: "UPDATE_TASK", project})
        }).catch((err) => {
            dispatch({type: "UPDATE_TASK_ERROR", err})
        })
    }


export const deleteTask = (id) => {
    if (!id){ 
        return {type: "DELETE_TASK_ERROR", err: "No id given"}
    }
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        const firestore = getFirestore()
        //const profile = getState().firebase.profile
        const ownerId = getState().firebase.auth.uid
        firestore.collection("tasks").doc(id).delete()
        .then(() => {
            dispatch({type: "DELETE_TASK"})
        }).catch((err) => {
            dispatch({type: "DELETE_TASK_ERROR", err})
        })
    }    
}




export const completeTask = task => 
    (dispatch, getState, {getFirestore}) => {
        const firestore = getFirestore()
        //const profile = getState().firebase.profile
        const ownerId = getState().firebase.auth.uid
        firestore.collection("tasks").doc(task.id).update({
            completed: 1,
            completedAt: new Date()
        })
        .then(() => {
            dispatch({type: "COMPLETE_TASK", task})
        }).catch((err) => {
            dispatch({type: "COMPLETE_TASK_ERROR", err})
        })
    }
    


