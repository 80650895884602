import React from "react"
//import { NavLink } from "react-router-dom"
import { connect } from "react-redux"
import { signOut } from "../../store/actions/authActions"
import { Link } from "react-router-dom"
import {
    Collapse,
   
    Input,
  
    CardHeader,
    FormGroup,
    Label,
    Col,
    Row,
   
    Button,

    Card,
    CardBody,
  
} from 'reactstrap';
import Device from "../device/Device"

class Settings extends React.Component {

    state = {
        sessionLength: this.props.settings.sessionLength,
        pauseLength: this.props.settings.pauseLength,
        collapse: false
    }

    toggle = () => {
        this.setState({ collapse: !this.state.collapse });
    }


    handleChange = (e) => {
        this.setState({
            [e.target.id]: parseInt(e.target.value)
        })
    }
    handleSaveSettings = () => {
        this.props.saveSettings(this.state);
    }



    render() {

        return (

            <Card id="settings-toggler" style={{"background":"rgba(255,255,255,0.7)"}}>

                <CardHeader onClick={this.toggle}>SETTINGS</CardHeader>

                <Collapse isOpen={this.state.collapse}>
                    <CardBody>

                        <Card className="mb-3" >
                            <CardBody className="text-left" >



                                <FormGroup row>
                                    <Label for="sessionLength" sm={4}>Session length</Label>
                                    <Col>
                                        <Input type="number" name="sessionLength" id="sessionLength" onChange={this.handleChange} value={this.state.sessionLength} />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label for="pauseLength" sm={4}>Pause length</Label>
                                    <Col >
                                        <Input type="number" name="pauseLength" id="pauseLength" onChange={this.handleChange} value={this.state.pauseLength} />
                                    </Col>
                                </FormGroup>

                                <div className="clearfix">
                                    <Button className="float-right" onClick={this.handleSaveSettings}>Save Settings</Button>
                                </div>

                                </CardBody>
                        </Card>
                                <Device />

                                <Card  >
                            <CardBody className="text-left" >
                                <Row>
                                   
                                    <Col>
                                        <strong>{this.props.profile.firstName} {this.props.profile.lastName}</strong>
                                        <br/>{this.props.auth.email}
                                    </Col>
                                    <Col className="text-right">
                                        <Button outline onClick={this.props.signOut}> Log out</Button>
                                    </Col>
                                </Row>



                            </CardBody>
                        </Card>
                    </CardBody></Collapse>

            </Card>
        )
    }

}


const mapStateToProps = (state) => {
    return ({
        settings: state.settings,
        profile: state.firebase.profile,
        auth: state.firebase.auth
    })
}

const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => dispatch(signOut()),
        saveSettings: (settings) => dispatch({ type: "SAVE_SETTINGS", settings })

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings)