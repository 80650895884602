import React, { Component } from 'react'
import { connect } from "react-redux"
import { login } from "../../store/actions/authActions"
import { Redirect,Link } from "react-router-dom"
import {Form,FormGroup,Input,Button,Alert,Container,Row,Col } from "reactstrap"

class Login extends Component {
    state = {
        email: "",
        password: ""
    }
    
    handleChange = (e) => {

        this.setState({
          [e.target.id] : e.target.value  
        })
    }

    handleSubmit = (e) => {
        e.preventDefault()
        console.log(this.state)
        this.props.login(this.state)     
    }

    render() {
        const {auth} = this.props
        if (auth.uid) return <Redirect to="/"></Redirect>

        const authError = this.props.authError ? <Alert color="danger">{this.props.authError}</Alert>:null

        return (
            
                    
                        <Form onSubmit={this.handleSubmit}>
                            <Input type="email" placeholder="Email" id="email" onChange={this.handleChange} className="mb-2" bsSize="lg"/>
                            <Input type="password" placeholder="Password" id="password" onChange={this.handleChange} bsSize="lg" className="mb-2"/>
                            <Button color="primary" size="lg" block className="mb-2">
                                Login
                            </Button>
                            {authError}
                            New user? <Link to="/signup">Create account</Link>
                        </Form>    
                   

            
        )
    }
}

const mapStateToProps = (state) => {
    return {
        authError: state.auth.authError,
        auth: state.firebase.auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        login: (creds) => dispatch(login(creds))
    } 
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
 