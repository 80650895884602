import React, { Component } from 'react'
import {connect} from 'react-redux'
import {createTask} from "../../store/actions/taskActions.js"
import { Redirect } from "react-router-dom"
import {
    Form,
    Input,
    Button,
    FormGroup,Col,Row
} from "reactstrap" 

class CreateTask  extends Component {
    state = {
        title: "",
        projectId: this.props.projectId
    }
    
    handleChange = (e) => {
        this.setState({
          [e.target.id] : e.target.value  
        })
        console.log(this.state)
    }

    handleSubmit = (e) => {
        console.log("CreateTask: handleSubmit", this.props.projectId)
        e.preventDefault();
        this.setState({projectId: this.props.projectId})
        this.props.createTask(this.state,this.props.projectId)
        this.setState({
            title: ""
        })
        this.props.onTaskCreated(this.props.projectId)
    }

    render() {
        const {auth} = this.props
        if (!auth.uid) return <Redirect to="/signin"></Redirect>
        return (
            <Form onSubmit={this.handleSubmit} className="mt-3">
            <Row>
                <Col>
                    <FormGroup> 
                        <Input type="text" value={this.state.title}  placeholder="Add new task" id="title" onChange={this.handleChange}/>
                    </FormGroup>
                    
                </Col>
                <Col sm="4"><Button block >Add task</Button></Col>
            </Row>
            </Form>    
           
        )
    }
}

const mapStateToProps = (state) => {
    return( {
        auth: state.firebase.auth
    })

}
const mapDispatchToProps = (dispatch) => {
    return {
        createTask: (task,projectId) => dispatch(createTask(task,projectId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateTask)