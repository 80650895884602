export const connectDevice = (device) => {
    return({type: "DEVICE_CONNECTED", device})    
}

export const disconnectDevice = (device) => {
    return({type: "DEVICE_DISCONNECTED", device})    
}

export const connectPort = (port) => {
    
    return({type: "CONNECT_PORT", port})
}