import React, { Component } from 'react'
import { Alert,Button,Container,Row,Col } from "reactstrap"
import serial from "./../../serial/Serial"
import { connect,dispatch } from "react-redux"
import { connectDevice, disconnectDevice, connectPort } from "../../store/actions/deviceActions"


class Device extends Component {
/*
    state = {
        port:null
    }
*/
/*
    send = (text) => {
        if (this.props.device.connected){
            const textEncoder = new TextEncoder();
            const data = textEncoder.encode(text)
            this.state.port.send(data)
        }
    }
*/

    receive = (data) => {
        const textDecoder = new TextDecoder()
        const text = textDecoder.decode(data).trim()
        if (text === "B"){
            this.props.buttonPushed();
        } else{
            console.log("text from device: ",text);
        }
    }


    connect = (port) => {
        
        console.log("connect", port)
        if (port.device_){
           
            console.log('Connecting to ' + port.device_.productName + '...');
            port.connect().then(() => {
                console.log('Connected.');
                this.props.connectDevice() // update state
                for (let i=0; i<100;i=i+5){
                    let pString = i.toString()
                    while (pString.length < 3) pString = "0" + pString;
                    this.props.device.port.sendText("L"+ pString)
                }
                

                for (let i=100; i>1;i=i-5){
                    let pString = i.toString()
                    while (pString.length < 3) pString = "0" + pString;
                    this.props.device.port.sendText("L"+ pString)
                }
                this.props.device.port.sendText("0100")
                
                port.onReceive = (data) => this.receive(data)
                port.onReceiveError = error => {
                    console.log('Receive error: ' + error);
                    this.props.disconnectDevice()
                }
            }, error => {
                console.log('Connection error: ' + error);
                this.props.disconnectDevice()
            });
        }
    };



    handleConnect = () => {
        console.log("handleConnect")
        serial.getPorts().then((ports) => {
            if (ports.length === 0) {
                console.log("no port")
                const port = serial.requestPort()
                this.props.connectPort(port);
                this.connect(port);
            } else {
                const port = ports[0]
                this.props.connectPort(port);
                this.connect(port);
            }
        })
    }

    componentDidUpdate = (prevProps, prevState) => {
        
        console.log("Device:componentDidUpdate:: prevProps:", prevProps)
        // Should we send state to device?
        if (!prevProps.device.sendStateToDevice && this.props.device.sendStateToDevice){
            console.log("sendingStateToDevice")
            console.log("totalRunningTime: ",this.props.session.totalRunningTime)
            console.log("length: ",this.props.session.length)
            
            if (this.props.device.connected){
                const percent = Math.round(100*(1 - this.props.session.totalRunningTime / this.props.session.length)) 
                let pString = percent.toString()
                while (pString.length < 3) pString = "0" + pString;
                console.log("percent finished: ", pString)
                if (this.props.session.running) {
                    this.props.session.isPauseSession ? this.props.device.port.sendText("h" + pString) : this.props.device.port.sendText("H" + pString)
                }else if (this.props.session.startedAt > 0) {
                    this.props.session.isPauseSession ? this.props.device.port.sendText("l" + pString) : this.props.device.port.sendText("L" + pString)
                } else if (this.props.session.task) {
                    this.props.device.port.sendText("L100")
                
                } else {
                    this.props.device.port.sendText("0100")
                }
            }
            this.props.sendStateToDeviceHandled()

        }
    }


    render() {
        console.log("props: ",this.props)
        const connectButton = this.props.device.connected  
        ? 
        <Alert color="success">
            Connected to device
        </Alert>
        : 
        <Alert color="warning" className="">
            <Row>
            <Col sm="auto">No time box connected </Col>
            <Col className="text-right"><Button color="warning" outline className="" onClick={this.handleConnect}>Connect</Button></Col>
            </Row>
        </Alert>


        return (
           
                <div>
                        {connectButton}    
                </div>  
            
        );
    }
}

const mapStateToProps = (state) => {
    return {
        session: state.session,
        device: state.device
    }
}
const mapDispathToProps = (dispatch) => {
    return {
        connectDevice: (device) => dispatch(connectDevice(device)),
        disconnectDevice: (device) => dispatch(disconnectDevice(device)),
        buttonPushed: () => dispatch({type:"BUTTON_PUSHED"}),
        sendStateToDeviceHandled: () => dispatch({type: "SEND_STATE_TO_DEVICE_HANDLED"}),
        connectPort: (port) => dispatch(connectPort(port))
    }
}


export default connect(mapStateToProps,mapDispathToProps)(Device)