import React, { Component } from 'react'
import { Redirect,Link } from "react-router-dom"
import {connect} from 'react-redux'
import { signUp } from "../../store/actions/authActions"
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    Button,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Form,
    Row,
    Container,
    Col,
    Alert,
    Input
} from 'reactstrap';

class SignUp extends Component {
    state = {
        email: "",
        password: "",
        firstName:"",
        lastName:""
    }
    
    handleChange = (e) => {
        this.setState({
          [e.target.id] : e.target.value  
        })
     
    }

    handleSubmit = (e) => {
        this.props.signUp(this.state)   
        e.preventDefault()
    }

    render() {
        const {auth, authError} = this.props
        if (auth.uid) return <Redirect to="/"></Redirect>
        return (
         
                    
                        <Form onSubmit={this.handleSubmit}>
                            
                            <Input type="email" placeholder="Email" id="email" onChange={this.handleChange} className="mb-2" bsSize="lg"/>
                            <Input type="password" placeholder="Password" id="password" onChange={this.handleChange} bsSize="lg" className="mb-2"/>
                            <Input type="input" placeholder="First Name" id="firstName" onChange={this.handleChange} className="mb-2" bsSize="lg"/>
                            <Input type="input" placeholder="Last Name" id="lastName" onChange={this.handleChange} className="mb-2" bsSize="lg"/>
                            <Button color="primary" size="lg" block className="mb-2">
                                Sign Up
                            </Button>
                            {authError}
                            Got an account? <Link to="/login">Login here</Link>
                        </Form>    
                 

  
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        authError: state.auth.authError
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        signUp: (newUser) => dispatch(signUp(newUser))
    } 
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUp)



