const initState = {   
    startedAt: 0,
    interuptions: 0,
    setStartedAt: 0,
    setRunningTime: 0,
    prevTotalRunningTime: 0,
    totalRunningTime: 0,
    length: 1200000,
    running: 0,
    task: null,
    taskId: 0,
    isPauseSession: 0,
    projectId:0
}

const sessionReducer = (state = initState, action) => {
    switch(action.type){
        case "START_SESSION": 
            return {
                ...state,
                ...action.session
            }
        case "START_PAUSE_SESSION":
            const pauseSession = {
                ...initState,
                ...action.session
            }  
            console.log("pauseSession", pauseSession)
            return pauseSession
        case "PAUSE_SESSION":
            return {
                ...state,
                ...action.session
            }
        case "TICK_SESSION":
            let newState = {
                ...state,
                ...action.session
            }
            console.log("TICK: Redcuing state to: ", newState)
            return newState
        case "RESET_SESSION":
            
            const prevTaskId = action.session ? action.session.taskId : 0
            const prevProjectId = action.session ? action.session.projectId : 0

            console.log("sessionReducer:: prevTaskId: ",prevTaskId)
            const resetState = {
                ...initState,
                prevTaskId,
                prevProjectId
            }
            console.log("sessionReducer:: resetState: ",resetState)
            return resetState
        case "SELECT_SESSION_TASK":
            return {
                ...state,
                task: action.task,
                taskId: action.task.id
            }
        case "SAVE_SESSION":
            return state

        case "SELECT_PROJECT":
            newState = {
                ...state,
                projectId: action.projectId
            }
            console.log("Select project: Redcuing state to: ", state)
            return newState   
        default:
            return state
    }
}
export default sessionReducer