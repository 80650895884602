const initState = {
//    sessionLength: 1500000,  // 25 minutes
//    pauseLength: 300000, // 5 minutes

    sessionLength: 10000,  // 6 seconds
    pauseLength: 10000, // 3 seconds
    

}

const settingsReducer = (state = initState, action) => {
    switch(action.type){
        case "SAVE_SETTINGS": 
            console.log("save settings", action.settings)
            return {
                ...state,
                ...action.settings
            }
        default:
            return state
    }
}
export default settingsReducer