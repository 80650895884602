import React, { Component } from 'react';
import {BrowserRouter, Switch, Route} from "react-router-dom"
import NavigationBar from "./components/layout/NavigationBar"
import Dashboard from "./components/dashboard/Dashboard"
import Login from "./components/auth/Login"
import SignUp from "./components/auth/SignUp"


import {Container,Row,Col} from "reactstrap"
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faCheckSquare,faEdit,faTrash,faPlay,faPause,faStop,faCheck,faBan,faMinusCircle,faCheckCircle } from '@fortawesome/free-solid-svg-icons'

library.add(fab, faCheckSquare,faEdit,faTrash,faPlay,faPause,faStop,faCheck,faBan,faMinusCircle,faCheckCircle)

class App extends Component {

  
  render() {
    return (
      <BrowserRouter>
       
            <Container>
                <Row>
                    <Col sm="12" md={{ size: 8, offset: 2 }} lg={{ size: 8, offset: 2 }} xl={{ size: 6, offset: 3 }} className="mt-5">
                      
                      <NavigationBar/>
           
                      <Switch>
                        <Route exact path="/" component={Dashboard}/>
                        <Route path="/login" component={Login}/>
                        <Route path="/signup" component={SignUp}/>
                       </Switch>
                    </Col>
                </Row>
          </Container>
      </BrowserRouter>
    );
  }
}

export default App;
