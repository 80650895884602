import React, { Component } from 'react'
import { 
    Col,
    Row,Input
} from "reactstrap"

import firebase from "../../config/fbConfig"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


class ProjectSelect extends Component {

    state = {
        selectedProjectTitle: "",
        editMode: false
    }

    handleProjectSelected = (e) => {
        this.setState({selectedProjectTitle: e.target.options[e.target.selectedIndex].text})
        this.props.onProjectSelected(e.target.value)
    }

    handleToggleEditTitle = () => {
        if (this.state.editMode) {
            this.handleTitleSave()
        }
        this.setState({editMode: !this.state.editMode})
    }

    handleTitleChange = (e) => {
        this.setState({
            selectedProjectTitle: (e.target.value)
        })
    }

    handleTitleSave = () => {
        const db = firebase.firestore();
        db.collection('projects').doc(this.props.selectedProjectId).update({
            title: this.state.selectedProjectTitle        
        })
        .then(function() {
            console.log("Document successfully written!");
        })
        .catch(function(error) {
            console.error("Error writing document: ", error);
        });
    }

    handleDeleteProject = (e) => {
        const db = firebase.firestore()
        db.collection('tasks').where("projectId","==",this.props.selectedProjectId).get()
        .then((qs)=>{
            // Once we get the results, begin a batch
            var batch = db.batch();
            qs.forEach(function(doc) {
                // For each doc, add a delete operation to the batch
                batch.delete(doc.ref);
            });
            // Commit the batch
            return batch.commit();
        })
        db.collection('projects').doc(this.props.selectedProjectId).delete()
        this.props.onProjectSelected(0)

    }



    render() {
        const { projects} = this.props
        return (
            <Row>
                <Col>
                    {
                        this.state.editMode ? 
                            <Input type="text" autoFocus value={this.state.selectedProjectTitle} onChange={this.handleTitleChange}/>
                        :
                            <Input type="select" value={this.props.selectedProjectId} onChange={this.handleProjectSelected}>
                                <option value="0"> - Select Project - </option>
                                { projects && projects.map(project => {
                                    return (
                                        <option key={project.id} data={project.title} value={project.id} >{project.title}</option>
                                    )
                                })}
                            </Input>
                    }
                </Col>
                {
                    this.props.editProject && this.props.selectedProjectId != 0 ? 
                    <Col xs="2" sm="2" >
                        <FontAwesomeIcon  size="lg"  icon="minus-circle" onClick={this.handleDeleteProject} />
                        <FontAwesomeIcon  size="lg"  icon="edit" onClick={this.handleToggleEditTitle}/>
                    </Col>
                    : null 

                }
            </Row>      

        
            
        )
    }
}


export default ProjectSelect