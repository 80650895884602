export const startSession = (session) => {
    if (!session.startedAt){
        session.startedAt = Date.now()
    }
    return({type: "START_SESSION", session})    
}

export const startPauseSession = (session,length) => {
    //console.log("startPauseSession: prevTaskId: ", prevTaskId)
    const pauseSession = {
        ...session,
        length,
        isPauseSession: 1,
        running: 1,
        setStartedAt: Date.now(),
        startedAt: Date.now()
    }
    console.log("startPauseSession: pauseSession: ", pauseSession)    
    return({type: "START_PAUSE_SESSION", session: pauseSession})    
}

export const pauseSession = (session) => {
    session.interuptions = session.interuptions + 1
    return({type: "PAUSE_SESSION", session}) 
}

export const tickSession = (session) => {
    
    return {
        type: "TICK_SESSION", session
    } 
}
export const selectSessionTask = (task) => {
    return {
        type: "SELECT_SESSION_TASK", task 
    } 
}

export const saveSession = (session) => {
  
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        const firestore = getFirestore()
        //const profile = getState().firebase.profile
        const ownerId = getState().firebase.auth.uid
        firestore.collection("sessions").add({
            ...session,
            endedAt: new Date()
        })
        .then(() => {
            dispatch({type: "SAVE_SESSION", session})
        }).catch((err) => {
            dispatch({type: "SAVE_SESSION_ERROR", err})
        })
    }
}  

