import { connect } from "react-redux"
import React, { Component } from 'react'
import { compose } from "redux"
import {
    Input,
    Form,
    Badge,
    ListGroupItem,
    Alert,
    Col,
    Row,
    UncontrolledCollapse,
    Card,
    CardBody,
} from 'reactstrap';
import { updateTask} from "../../store/actions/taskActions"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import firebase from "../../config/fbConfig"

class TaskTitle extends Component {
    
    state = {
        editMode : false,
        value : this.props.task.title,
        sessionCount: 0,
        sessions: null
    }
    
    handleChange = (e) => {
        this.setState({
            value: (e.target.value)
        })
    }
    
    handleSaveSettings = (e) => {
        e.preventDefault()
        this.props.updateTask({
            ...this.props.task,
            title: this.state.value
        });
        this.toggle()
    }

    toggle = () => {
        this.setState({editMode:!this.state.editMode})
    }

    componentDidMount = () => {
        console.log("get sessions for ", this.props.task.id)
        if (this.props.task.id){
            const db = firebase.firestore();
            db.collection('sessions').where('taskId','==',this.props.task.id).get()
            .then((querySnapshot) => {
                var data = querySnapshot.docs.map(function (documentSnapshot) {
                        //console.log("returning data:", documentSnapshot.data() )
                        return {
                            ...documentSnapshot.data(),
                            id:documentSnapshot.id
                        };
                    });
                    
                    this.setState({ sessions : data }) 
                    this.setState({ sessionCount: data.length})
            })
        }       
    }


    render() {
        const { task,session } = this.props
      
        return (
                <ListGroupItem>
                <Row>                
                    <Col>    
                    
                        {this.state.editMode ? 
                            <Form onSubmit={this.handleSaveSettings}>
                                <Input  autoFocus onChange={this.handleChange} value={this.state.value}/>
                            </Form>   
                        :
                            <div onClick={this.toggle} style={ task.completed ? {'textDecoration':'line-through','color':'#cccccc'}:null} >
                                {this.state.value} 
                                <Badge pill id="toggler" className="ml-2">{this.state.sessionCount}</Badge>
                            
                           
                            <UncontrolledCollapse toggler="#toggler">
                            <Card>
                                <CardBody>
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt magni, voluptas debitis
                                similique porro a molestias consequuntur earum odio officiis natus, amet hic, iste sed
                                dignissimos esse fuga! Minus, alias.
                                </CardBody>
                            </Card>
                            </UncontrolledCollapse>
                            </div>
                        }
                        
                        {session.startedAt && session.task && session.task.id == task.id ? 
                            <Alert className="mt-2" color='danger'>This task is having work done</Alert>:""
                        }
                        

                    </Col>
                    {session.startedAt && session.task && session.task.id == task.id ? null : 

                    <Col sm="3">
                        <FontAwesomeIcon  size="lg" disabled={session.running != 0 && session.task && (session.task.id == task.id)} className="float-right ml-1" onClick={() => this.props.onDelete(task.id)} icon="minus-circle" />
                        <FontAwesomeIcon color="secondary" size="lg"className="float-right ml-1" onClick={() => this.props.onComplete(task)} icon="check-circle" />
                                
                    </Col>
                    }
                </Row>
                </ListGroupItem>
              
            
        )
    }
}
 


    const mapDispatchToProps = (dispatch) => {
        return {
            updateTask: (task) => dispatch(updateTask(task)),
        }
    }

export default compose(
    connect(null, mapDispatchToProps),
    
)(TaskTitle)



