import authReducer from "./authReducer"
import taskReducer from "./taskReducer"
import {combineReducers} from "redux"
import { firestoreReducer } from "redux-firestore"
import { firebaseReducer } from "react-redux-firebase"
import sessionReducer from "./sessionReducer";
import deviceReducer from "./deviceReducer";
import settingsReducer from "./settingsReducer";


const rootReducer = combineReducers({
    auth: authReducer, 
    task: taskReducer,
    session: sessionReducer,
    settings: settingsReducer,
    device: deviceReducer,
    firestore: firestoreReducer,
    firebase:  firebaseReducer

})

export default rootReducer
