import React from "react"


class NavigationBar extends React.Component {
  
    
    render() {
       
        return (
                <div className="text-center">
                <img alt="Sessions" src="/img/sessions_logo.svg" className="mb-5" style={ {"width" : '70%'}}></img>
                </div>

        )
    }
}


export default NavigationBar