const initState = {
    connected: 0,
    buttonPushed: 0,
    sendStateToDevice: 0,
    port: null

}
const deviceReducer = (deviceState = initState, action) => {
switch(action.type){
    case "CONNECT_PORT":
        const newState = {
            ...deviceState,
            port: action.port
        }
        console.log("new state: ", newState)
        return newState
    case "DEVICE_CONNECTED": 
        console.log("device connected", action.device)
        return {
            ...deviceState,
            connected: 1
        }
    case "DEVICE_DISCONNECTED":
        console.log("device disconnected", action.device)
        return {
            ...deviceState,
            connected: 0
        }
    case "BUTTON_PUSHED":
        return {
            ...deviceState,
            buttonPushed: 1
        }     
    case "BUTTON_HANDLED":
        return {
            ...deviceState,
            buttonPushed: 0
        }     

    case "SEND_STATE_TO_DEVICE":
        return {
            ...deviceState,
            sendStateToDevice: 1
        }
    case "SEND_STATE_TO_DEVICE_HANDLED":
        return {
            ...deviceState,
            sendStateToDevice: 0
        }
    default:
        return deviceState
}
}
export default deviceReducer